/* Bootstrap */
// Import functions, variables, and mixins needed by other Bootstrap files
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root"; // Contains :root CSS variables used by other Bootstrap files

@import "~bootstrap/scss/containers"; // Add .container and .container-fluid classes
@import "~bootstrap/scss/grid"; // Add the grid system

@import "colors";
@import "font";
@import "toasrt";
@import "variables";

/* Material*/
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.opcionActualizar {
  cursor: pointer;
}

a.button3 {
  display: inline-block;
  padding: 0.3em 1.2em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 1em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #000000;
  background-color: #4eb5f1;
  text-align: center;
  transition: all 0.2s;
}

a.button3:hover {
  background-color: #4095c6;
}

@media all and (max-width:30em) {
  a.button3 {
    display: block;
    margin: 0.2em auto;
  }
}

.parrafo-oscuro {
  // font-family: $font-calibri-bold;
  font-size: 14px;
  line-height: 18px;
  // color: $color-siaf-gris-90;
}

.parrafo_small {
  // font-family: $font-calibri-regular;
  padding-top: 10px;
  font-size: 12px;
  line-height: 15px;
  margin: 0 0 0px !important;
  // color: $color-siaf-gris-90;
}

.mat-form-field-wrapper {
  padding-top: 0.5em;
}

 .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0,0,0,.03);
  cursor: not-allowed !important;
}
 .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0,0,0,0.3);
}

 .mat-form-field-appearance-outline .mat-form-field-outline {
  //color: rgb(0 0 0 / 30%);
}
